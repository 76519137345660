import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if(process.env.MIX_SENTRY_DSN_PUBLIC){
    Sentry.init({
        dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });
}
